<template>
  <div id="AddOrEditQuestionModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ id ? 'Atualizar' : 'Cadastrar' }} Questão
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="closeModal(false)"
          />
        </div>
        <form @submit.prevent="saveQuestion">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 mb-3">
                <label class="form-label">
                  Questão: <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  placeholder="Digite a questão"
                  :class="{
                    'is-invalid': v$.question.question.$error,
                  }"
                  v-model="v$.question.question.$model"
                />
                <span
                  v-if="v$.question.question.required.$invalid"
                  class="invalid-feedback"
                >
                  Campo Obrigatório
                </span>
                <span
                  v-if="v$.question.question.minLength.$invalid"
                  class="invalid-feedback"
                >
                  Campo deve possuir pelo menos 10 caracteres
                </span>
              </div>
              <div class="col-sm-12 col-md-6 mb-3">
                <label class="form-label">
                  Possui a opção correta? <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  placeholder="Selecione o tipo"
                  :class="{
                    'is-invalid': v$.question.hasCorrectOption.$error,
                  }"
                  v-model="v$.question.hasCorrectOption.$model"
                >
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
                <span
                  v-if="v$.question.hasCorrectOption.required.$invalid"
                  class="invalid-feedback"
                >
                  Campo Obrigatório
                </span>
              </div>
              <div class="col-sm-12 col-md-6 mb-3">
                <label class="form-label">
                  Pontos: <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  step="0.5"
                  class="form-control"
                  placeholder="Digite a questão"
                  :class="{
                    'is-invalid': v$.question.points.$error,
                  }"
                  v-model="v$.question.points.$model"
                />
                <span
                  v-if="v$.question.points.minValue.$invalid"
                  class="invalid-feedback"
                >
                  Campo Obrigatório
                </span>
              </div>
              <div
                class="col-sm-12 col-md-6 mb-3"
                v-if="question.hasCorrectOption == 1"
              >
                <label class="form-label">
                  Mensagem de resposta correta:
                </label>
                <textarea
                  class="form-control"
                  placeholder="Digite a mensagem de resposta correta"
                  v-model="question.messageCorrectOption"
                />
              </div>
              <div
                class="col-sm-12 col-md-6 mb-3"
                v-if="question.hasCorrectOption == 1"
              >
                <label class="form-label">
                  Mensagem de resposta incorreta:
                </label>
                <textarea
                  class="form-control"
                  placeholder="Digite a mensagem de resposta incorreta"
                  v-model="question.messageErrorOption"
                />
              </div>
              <div class="col-12 d-flex justify-content-between">
                <h5 class="fw-normal">Opções</h5>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  @click="addOption"
                >
                  Adicionar Opção
                </button>
              </div>

              <div
                class="col-sm-12 mb-1"
                v-for="(option, index) in options"
                :key="index"
              >
                <div class="row">
                  <div class="col-2 col-md-1">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="removeOption(index)"
                    >
                      <i class="ph-trash"></i>
                    </button>
                  </div>
                  <div
                    class="col-1 col-md-1 d-flex align-items-center text-center"
                  >
                    <div class="form-check form-switch">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="optionCorrect"
                        v-model="correctOption"
                        :id="`check_option_${index}`"
                        :value="index"
                      />
                      <label
                        class="form-check-label"
                        :for="`check_option_${index}`"
                      ></label>
                    </div>
                  </div>
                  <div class="col-9 col-md-10">
                    <input
                      type="text"
                      :id="`option_${index}`"
                      :name="`option_${index}`"
                      v-model="option.answer"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="closeModal(false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Atualizar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>{{ id ? 'Atualizar' : 'Cadastrar' }} </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, decimal, minValue } from '@vuelidate/validators'

export default {
  name: 'AddOrEditQuestionModal',
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      modalInstance: null,
      correctOption: 0,
      question: {
        uuid: this.$route.params.uuid,
        question: '',
        hasCorrectOption: 1,
        messageCorrectOption: '',
        messageErrorOption: '',
        points: '',
        options: [],
      },
      options: [],
      disableButton: false,
    }
  },

  methods: {
    addOption() {
      this.options.push({
        answer: '',
        isCorrect: 0,
      })
    },
    removeOption(index) {
      this.options.splice(index, 1)
    },

    async saveQuestion() {
      try {
        if (this.options.length < 2) {
          this.$root.$refs.notification.warning(
            'Cadastre pelo menos duas opções',
          )
          return false
        }
        this.options.forEach((option, index) => {
          // eslint-disable-next-line no-param-reassign
          this.options[index].isCorrect = index === this.correctOption ? 1 : 0
          if (option.answer === '') {
            this.$root.$refs.notification.warning('Preencha todas as opções')
            return false
          }
        })
        this.question.options = this.options
        this.disableButton = true
        let response = null
        if (this.id) {
          response = await this.$store.dispatch('updateQuestion', {
            uuid: this.question.uuid,
            question: this.question,
          })
        } else {
          response = await this.$store.dispatch('saveQuestion', {
            uuid: this.question.uuid,
            question: this.question,
          })
        }

        this.$root.$refs.notification.success(response.message)
        this.closeModal(true)
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    closeModal(reload) {
      this.modalInstance.hide()
      this.$emit('closeModal', reload)
    },
    showModal() {
      this.modalInstance = new window.bootstrap.Modal(
        document.getElementById('AddOrEditQuestionModal'),
        {
          backdrop: 'static',
          keyboard: false,
        },
      )
      this.modalInstance.show()
    },

    async getQuestion() {
      try {
        this.isLoadingSimulation = true
        this.question = await this.$store.dispatch('getQuestion', {
          id: this.id,
          uuid: this.question.uuid,
        })
        this.options = this.question.options
        this.correctOption = this.options.findIndex(
          (option) => option.isCorrect === 1,
        )
        this.isLoadingSimulation = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  validations() {
    return {
      question: {
        question: {
          required,
          minLength: minLength(10),
        },
        hasCorrectOption: {
          required,
        },
        points: {
          required,
          minValue: minValue(0),
        },
      },
    }
  },

  mounted() {
    if (this.id) {
      this.getQuestion(this.id)
    }
    this.showModal()
  },
}
</script>
