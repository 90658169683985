const formatToCurrencyBr = (value) => {
  const money = parseFloat(value)
  return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

const formatToCurrencyBrLabel = (value) => {
  const money = parseFloat(value)
  const transforNumber = money.toLocaleString('pt-br', {
    currency: 'BRL',
    style: 'currency',
  })
  return transforNumber
  // if (money > 0) {
  //   return `<span class="text-info">R$ ${transforNumber} </span>`
  // }

  // return `<span class="text-danger">R$ ${transforNumber} </span>`
}

// eslint-disable-next-line import/prefer-default-export
export { formatToCurrencyBr, formatToCurrencyBrLabel }
