<template>
  <div class="card card-body p-0 mt-2">
    <v-grid
      theme="compact"
      :source="groups"
      :columns="columns"
      :grouping="grouping"
      :filter="filterNames"
      :columnTypes="columnTypes"
      autoSizeColumn="true"
      resize="true"
      exporting="true"
    ></v-grid>
  </div>
</template>
<style>
revo-grid {
  height: 450px;
}
</style>
<script>
import VGrid, { VGridVueTemplate } from '@revolist/vue3-datagrid'
import AcessGroupBtn from '@/components/tables/AcessGroupBtn.vue'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import { entryTypeLabel } from '@/utils/status'
import { formatToCurrencyBrLabel } from '@/utils/currency'

export default {
  name: 'GroupTable',
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  components: {
    VGrid,
  },
  data() {
    return {
      dateTimeSqlToDateTimeBR,
      entryTypeLabel,
      formatToCurrencyBrLabel,
      filter: true,
      columns: [
        {
          prop: 'number',
          name: 'ID',
          sortable: true,
          order: 'asc',
          size: 100,
        },
        {
          prop: 'startAt',
          name: 'Data Inicial',
          columnType: 'date',
          sortable: true,
          filter: true,
          order: 'asc',
          autoSize: false,
          size: 250,
          cellTemplate: (createElement, props) => {
            return createElement(
              'span',
              {
                class: '',
              },
              dateTimeSqlToDateTimeBR(props.model[props.prop]),
            )
          },
        },
        {
          prop: 'endAt',
          name: 'Data Final',
          columnType: 'date',
          sortable: true,
          filter: true,
          order: 'asc',
          autoSize: false,
          size: 250,
          cellTemplate: (createElement, props) => {
            return createElement(
              'span',
              {
                class: '',
              },
              dateTimeSqlToDateTimeBR(props.model[props.prop]),
            )
          },
        },
        {
          prop: 'createdBy',
          name: 'Criado Por',
          sortable: true,
          order: 'asc',
          size: 400,
        },
        {
          prop: 'unities',
          name: 'Unidades',
          sortable: true,
        },
        {
          prop: 'uuid',
          name: 'Acessar',
          sortable: true,
          order: 'asc',
          autoSizeColumn: true,
          pin: 'colPinEnd',
          cellTemplate: VGridVueTemplate(AcessGroupBtn),
        },
      ],
    }
  },
  watch: {},
  methods: {
    acessData() {},
  },
}
</script>
