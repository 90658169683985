<template>
  <div class="row container">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="fw-light">
        <router-link to="/reports"
          ><i class="ph-caret-left me-2"></i
        ></router-link>
        Relatório Geral
      </h3>
      <div></div>
    </div>
    <div class="col-12">
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Posição</th>
            <th scope="col">Nome</th>
            <th scope="col">Pontos</th>
            <th scope="col">Contratação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ranking" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.name }}</td>
            <td>{{ item.points }}</td>
            <td>{{ dateTimeSqlToDateWithT(item.birthday) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { dateTimeSqlToDateWithT } from '@/utils/date'

export default {
  name: 'ReportsGeneral',
  data() {
    return {
      ranking: [],
      isLoadingData: true,
      dateTimeSqlToDateWithT,
    }
  },
  methods: {
    async getRanking() {
      try {
        this.ranking = await this.$store.dispatch('getGeneralRanking')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getRanking()
  },
}
</script>
