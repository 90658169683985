<template>
  <AddOrEditGroupModal
    v-if="showModalGroup"
    :id="selectedGroup"
    @closeModal="closeModal"
  />
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="fw-light">Questionários</h3>
      <div>
        <button class="btn btn-primary rounded-pill" @click="openModalGroup()">
          Cadastrar Questionário
        </button>
        <router-link class="btn btn-yellow rounded-pill ms-2" to="/reports">
          Relatórios
        </router-link>
      </div>
    </div>
    <div class="col-12">
      <GroupTable :groups="groups" />
    </div>
  </div>
</template>
<script>
import AddOrEditGroupModal from '@/components/modals/AddOrEditGroupModal.vue'
import GroupTable from '@/components/tables/GroupTable.vue'

export default {
  name: 'Home',
  data() {
    return {
      selectedGroup: null,
      showModalGroup: false,
      groups: [],
    }
  },
  components: {
    AddOrEditGroupModal,
    GroupTable,
  },
  methods: {
    openModalGroup(uuid) {
      this.selectedGroup = uuid ?? null
      this.showModalGroup = true
    },
    closeModal(needReload) {
      if (needReload) {
        this.getGroups()
      }
      this.showModalGroup = false
    },
    async getGroups() {
      try {
        this.groups = await this.$store.dispatch('getGroups')
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getGroups()
  },
}
</script>
